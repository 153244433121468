import GATSBY_COMPILED_MDX from "/opt/build/repo/src/year-in-review/2022.mdx";
import React from 'react';
import {graphql} from "gatsby";
import Layout from "../willoughby/layout";
function Letter({children}) {
  return React.createElement(Layout, null, React.createElement("div", {
    className: "content-container"
  }, React.createElement("article", {
    className: "letter"
  }, children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Letter, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head({data}) {
  let post = data.mdx;
  return React.createElement("title", null, post.frontmatter.title);
}
export const query = graphql`
  query LetterQuery($id: String!) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        featureImage {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
